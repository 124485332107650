import * as React from "react";
import { InjectedFormProps, reduxForm } from "redux-form";
import Button from "src/components/Button";
import TextInput from "src/components/TextInput";
import Typography from "src/components/Typography";
import styles from "./ResetPassword.module.css";

interface Props extends InjectedFormProps<FormData> {}

type FormData = {
  password: string;
  confirmedPassword: string;
};

class RegistrationFrom extends React.Component<Props> {
  public render() {
    const { props } = this;
    const isDisabled = !!(
      props.submitting ||
      !props.anyTouched ||
      props.error ||
      props.invalid
    );

    return (
      <form onSubmit={props.handleSubmit} className={styles.formWrapper}>
        <Typography type="h3" black>
          <h3>Изменение пароля</h3>
        </Typography>
        <Typography type="text" black>
          Введите новый пароль дважды.
        </Typography>
        <div className={styles.inputWrapper}>
          <TextInput name="password" label="Пароль" black type="password" />
          <TextInput
            name="confirmedPassword"
            label="Повторите пароль"
            black
            type="password"
          />
        </div>
        <div className={styles.buttonWrapper}>
          <Button disabled={isDisabled} type="submit">
            СОХРАНИТЬ ИЗМЕНЕНИЯ
          </Button>
        </div>
      </form>
    );
  }
}

const validate = (values: { password: string; confirmedPassword: string }) => {
  const errors: { password?: string; confirmedPassword?: string } = {};
  if (values.confirmedPassword !== values.password) {
    errors.confirmedPassword = "Пароли не совпадают";
  }
  return errors;
};

export default reduxForm<FormData>({
  form: "RestPasswordFrom", // a unique identifier for this form
  validate,
})(RegistrationFrom);
