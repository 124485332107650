import queryString from "query-string";
import * as React from "react";
import Api from "src/api";
import icon from "src/images/resetPassIcon.svg";
import FullWithLayout from "src/routes/FullWithLayout";
import { PageProps } from "src/types";
import styles from "./ResetPassword.module.css";
import ResetPasswordForm from "./ResetPasswordForm";

type State = {
  token: string;
  email: string;
};

class ResetPassword extends React.Component<PageProps, State> {
  public state = {
    token: "",
    email: "",
  };

  public async componentDidMount() {
    const query = queryString.parse(this.props.location.search);
    const isToken = typeof query.token === "string";
    const isEmail = typeof query.email === "string";

    if (isToken && isEmail) {
      // @ts-ignore
      this.setState({ email: query.email, token: query.token });
      return;
    }
    await this.props.navigate("/");
  }

  public render() {
    return (
      <FullWithLayout white sideBar={<img src={icon} />}>
        <div className={styles.wrapper}>
          <ResetPasswordForm onSubmit={this.handleSubmit} />
        </div>
      </FullWithLayout>
    );
  }

  private handleSubmit = async (data) => {
    await Api.sendPasswordsLink({
      ...data,
      email: this.state.email,
      token: this.state.token,
    });
    await this.props.navigate("/");
  };
}

export default ResetPassword;
