import React from "react";
import Seo from "src/components/Seo";
import ResetPasswordRoute from "src/routes/ResetPassword";
import { PageProps } from "src/types";

const ResetPassword = (props: PageProps) => (
  <>
    <Seo pageTitle="Восстановление пароля" />
    <ResetPasswordRoute {...props} />
  </>
);

export default ResetPassword;
